import React, { useEffect, useRef, useState } from "react";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import PG_P_RATING_REVIEW_CONTENT from "../components/pro/RatingReview/index.content";
import { PAGES } from "../helpers/constants";
import { isSavedUserInfo } from "../helpers";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/pro/HomeIntro";
import ProProInstall from "../components/pro/ProProInstall";
import WhyAmzscout from "../components/pro/WhyAmzscout";
import UsingAmzscout from "../components/pro/UsingAmzscout";
import Profits from "../components/pro/Profits";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import Testimonials from "../components/pro/Testimonials";
import PartnerBrand from "../components/common/PartnerBrand";
import ChromeRating from "../components/pro/ChromeRating";
import RatingReview from "../components/pro/RatingReview/";
import StandardFormBlockContainer from "../containers/common/forms/StandardFormBlockContainer";
import "../components/pro/index.scss";

const PgP = () => {
  const [isSecondStateCollectEmail, setIsSecondStateCollectEmail] =
    useState(false);
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const tariffs = useRef(null);
  const { postData } = PG_P_RATING_REVIEW_CONTENT;
  const closeYoutubeModal = () => setUrlYoutubeModal("");

  useEffect(() => {
    if (isSavedUserInfo()) {
      setIsSecondStateCollectEmail(true);
    }
  }, []);

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.matchMedia("(max-width: 767px)").matches);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <Layout
      isSecondStateCollectEmail={isSecondStateCollectEmail}
      tariffsElement={tariffs}
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
      isShowYoutubeModal={isShowYoutubeModal}
      customClass="PgP"
    >
      <Seo
        title="AMZScout PRO Extension - Try for Free"
        description="With the powerful AMZScout PRO Extension, access sales volume, price history, competition score, and other statistics on every Amazon product right in your browser. Start your free trial."
        page="pro"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
      />
      <ChromeRating />
      <PartnerBrand />
      <RatingReview
        isMobile={isMobile}
        excellentText="Excellent"
        posts={postData}
      />
      <UsingAmzscout />
      <StandardFormBlockContainer
        pageFormState={isSecondStateCollectEmail}
        updatePageFormState={setIsSecondStateCollectEmail}
        buttonTextFirstState="Try PRO Extension For Free"
        buttonTextSecondState="Try PRO Extension For Free!"
        page={PAGES.PRO}
        customClass={
          isSecondStateCollectEmail
            ? "PgP-standardFormPageBlock-secondState"
            : ""
        }
        title="Find products that will bring you $5,000/mo or more with a PRO Extension FREE TRIAL!"
      />
      <CustomizableContainer pricingRef={tariffs} subTitlePro />
      <ProProInstall />
      <Profits />
      <WhyAmzscout />
      <StandardFormBlockContainer
        pageFormState={isSecondStateCollectEmail}
        updatePageFormState={setIsSecondStateCollectEmail}
        buttonTextFirstState="Try PRO Extension For Free"
        buttonTextSecondState="Try PRO Extension For Free!"
        page={PAGES.PRO}
        customClass={
          isSecondStateCollectEmail
            ? "PgP-standardFormPageBlock-secondState"
            : "PgP-standardFormPageBlock-firstState"
        }
        title="Enter your email to start your FREE TRIAL!"
      />
      <Testimonials setUrlYoutubeModal={setUrlYoutubeModal} />
    </Layout>
  );
};

export default PgP;
